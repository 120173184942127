import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useForm, Controller, UseFormMethods } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import { useQueryClient } from "react-query";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Input, OutlinedInput } from "@ui-kit/InputFields";
import DatePicker from "@ui-kit/DatePicker";
import { useCss } from "./styles";
import SuccessStep from "@components/SuccessStep";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { required, email } from "@validators";
import { GENDERS } from "@constants/genders";
import { ROUTES } from "@constants";
import { CHILDREN_API, FACILITY_API, Models } from "@services/api";
import Select from "@ui-kit/Select";
import DividerImg from "@images/divider_shadow.png";
import { getFacilityFullAddress, isFacilityOwner } from "@selectors";
import {
  ServiceInquryPreferedEnrollmentSchedule,
  ServiceInqurySchoolTourPreferences,
  TourPreferenceTextMap,
} from "@constants/service-inquiry";
import { goToFormError } from "@helpers/goToFormError";
import { useNotificationStore } from "@store/NotificationStore";
import { Spacer } from "@ui-kit/Spacer";
import { QuitDialog } from "@components/QuitDialog";
import { ExistedWarning } from "@pages/DeepDivePanel/ExistedInquiryWarning";
import { SelfInquiryWarning } from "@pages/DeepDivePanel/SelfInquiryWarning";
import { ServerError } from "@models/common";
import { must } from "@utils/must";
import { QUERIES } from "@constants/queries";
import { expectServerError } from "@helpers/serverError";

type FormFields = {
  childBirthday: string;
  programEnrollmentDay: string;
  comment: string;
  name: string;
  parentEmail: string;
  schoolTourPreferences: Models.FacilityContactRequestTourPreference;
  preferedEnrollmentSchedule: Models.FacilityContactRequestSchedule;
  gender: Models.Gender;
};

const isFieldOfInquiry = (field: string) => {
  return field === "programEnrollmentDay" || field === "childBirthday";
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (
      paths[1] === "programEnrollmentDay" &&
      e.code === "validation_min_greater_equal_than_required"
    ) {
      setError(paths[1], {
        message: "Date is in the past",
        type: "manual",
      });
      errorHandled = true;
      return true;
    }

    if (isFieldOfInquiry(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

const TourInquiryPage: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const [inited, setInited] = useState(false);
  const [existingInquiry, setExistingInquiry] = useState<number | null>(null);
  const [showSelfInquiry, setShowSelfInquiry] = useState(false);

  const [, { setNotification, setUnknownErrorNotification }] =
    useNotificationStore();
  const prevUser = usePrevious(user);
  const {
    handleSubmit,
    control,
    errors,
    formState,
    setValue,
    trigger,
    watch,
    setError,
  } = useForm<FormFields>({
    defaultValues: {
      childBirthday: "",
      schoolTourPreferences: "" as Models.FacilityContactRequestTourPreference,
    },
    shouldUnregister: false,
  });
  const [step, setStep] = useState(1);
  const [quit, setQuit] = useState(false);
  const css = useCss();

  useEffect(() => {
    const query = qs.parse(search);

    if (!!query.defaultPreference) {
      setTimeout(() => {
        setValue("schoolTourPreferences", query.defaultPreference);
        history.replace({
          search: qs.stringify({
            ...query,
            defaultPreference: undefined,
          }),
        });
      }, 100);
    }

    if (!user) {
      setInited(true);
      return;
    }

    void getExistingInquiry();
    void CHILDREN_API.childrenIndex().then(({ data }) => {
      if (data.data.length > 0) {
        setValue("childBirthday", data.data[0].birthday);
      }
    });
  }, []);

  useEffect(() => {
    if (!prevUser && user) {
      void (async () => {
        const {
          data: { data },
        } = await FACILITY_API.facilityContactRequestsIndex({
          facilityId: facility.id,
          parentUserId: -1,
        });

        if (!data.length) {
          if (isFacilityOwner(facility, user)) {
            setShowSelfInquiry(true);
          } else {
            void sendRequest();
          }
        } else {
          setExistingInquiry(must(data[0].id));
        }
      })();
    }
  }, [user]);

  const checkAuthorization = () => {
    if (!user) {
      history.replace(ROUTES.SIGNUP_FORM);
    } else {
      void sendRequest();
    }
  };

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        checkAuthorization();
      } else {
        goToFormError();
      }
    });
  };

  const sendRequest = handleSubmit(
    async ({
      parentEmail,
      name,
      childBirthday,
      programEnrollmentDay,
      schoolTourPreferences,
      preferedEnrollmentSchedule,
      gender,
      comment,
    }) => {
      try {
        await FACILITY_API.facilityContactRequestsCreate({
          facilityContactRequest: {
            childBirthday,
            childGender: gender,
            childHealthIssues: [],
            childTemperament: [],
            comment,
            facilityId: facility.id,
            parentEmail,
            parentFirstName: name.split(" ")[0],
            parentLastName: name.split(" ")[1] || "",
            programEnrollmentDay,
            programEnrollmentSchedule: preferedEnrollmentSchedule || null,
            programPreferredPaymentMethods: [],
            programServiceWishList: [],
            programTourPreference: schoolTourPreferences || null,
          },
        });

        void queryClient.invalidateQueries(QUERIES.USER_CHATS);
        void queryClient.invalidateQueries(QUERIES.CHAT_FACILITIES);
        setStep(2);
      } catch (e) {
        const errors = expectServerError(e, setUnknownErrorNotification);

        if (!processServerErrors(errors, setError)) {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
        }
      }
    },
  );

  const onClose = () => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  };

  const getExistingInquiry = useCallback(async () => {
    const {
      data: { data },
    } = await FACILITY_API.facilityContactRequestsIndex({
      facilityId: facility.id,
      parentUserId: -1,
    });

    if (!data.length) {
      setInited(true);
      return;
    }

    history.replace({
      pathname: `/map/${facility.id}/service-inquiry/${data[0].id}/response`,
      search,
    });
  }, [user, facility]);

  if (!inited) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => setQuit(true)}
      data-test="tour-inquiry-dialog"
      tier={facility.subscriptionTier}
      title="School tour request"
    >
      <div css={css.container}>
        {!!existingInquiry && (
          <ExistedWarning
            onClose={() => setExistingInquiry(null)}
            inquiryId={existingInquiry}
          />
        )}
        {showSelfInquiry && (
          <SelfInquiryWarning
            onClose={() => setShowSelfInquiry(false)}
            onSend={() => {
              setShowSelfInquiry(false);
              void sendRequest();
            }}
          />
        )}

        {quit && (
          <QuitDialog
            onQuit={onClose}
            onClose={() => setQuit(false)}
            tier={facility.subscriptionTier}
          />
        )}

        {step === 2 && (
          <SuccessStep
            text="Submitted"
            content={
              <Fragment>
                <Typography align="center">
                  Facility will be in-touch shortly
                </Typography>
                <img
                  src={DividerImg}
                  alt="divider"
                  style={{
                    display: "block",
                    margin: "30px auto",
                    width: 300,
                  }}
                />
                <div css={css.doneAction}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test="done-button"
                    size="large"
                    onClick={onClose}
                  >
                    Done
                  </Button>
                </div>
              </Fragment>
            }
          />
        )}

        {step === 1 && (
          <Fragment>
            <Typography bolded align="center" gutterBottom>
              {facility.name}
            </Typography>
            <Typography align="center">
              {getFacilityFullAddress(facility)}
            </Typography>

            <Spacer size="medium" />

            <Typography variant="h5" align="center" css={css.sectionTitle}>
              School tour options
            </Typography>

            <Controller
              render={({ onChange, ...controllerProps }) => (
                <Select
                  {...controllerProps}
                  items={ServiceInqurySchoolTourPreferences}
                  labelProp="name"
                  error={errors.schoolTourPreferences?.message}
                  label="Select preferred experience"
                  onChange={(event) => {
                    setValue(
                      "comment",
                      "Hello! We are interested in a school tour:\n\n" +
                        TourPreferenceTextMap[event.value.id],
                    );
                    onChange(event);
                  }}
                />
              )}
              rules={required()}
              name="schoolTourPreferences"
              control={control}
            />

            {!!watch("schoolTourPreferences") && (
              <Controller
                render={(controllerProps) => (
                  <OutlinedInput
                    {...controllerProps}
                    multiline
                    placeholder="Questions of interest"
                    rowsMax="6"
                    maxChars={750}
                    error={errors.comment?.message}
                  />
                )}
                rules={required()}
                defaultValue=""
                name="comment"
                control={control}
              />
            )}

            <Spacer size="medium" />

            <Typography variant="h5" align="center" css={css.sectionTitle}>
              Family profile
            </Typography>

            <Controller
              render={(rest) => (
                <Input
                  {...rest}
                  fullWidth
                  error={errors.name?.message}
                  label="Parent name"
                />
              )}
              rules={required()}
              name="name"
              defaultValue={user ? `${user.firstName} ${user.lastName}` : ""}
              control={control}
            />

            <Controller
              render={(rest) => (
                <Input
                  {...rest}
                  fullWidth
                  error={errors.parentEmail?.message}
                  data-test="email-field"
                  label="Parent email"
                />
              )}
              rules={email()}
              name="parentEmail"
              defaultValue={user?.email || ""}
              control={control}
            />

            <Spacer size="medium" />

            <Controller
              render={(rest) => (
                <DatePicker
                  {...rest}
                  fullWidth
                  error={errors.childBirthday?.message}
                  data-test="birthday-field"
                  label="Child's birthday"
                  variant="date"
                />
              )}
              rules={required()}
              name="childBirthday"
              control={control}
            />

            <Controller
              render={(rest) => (
                <Select
                  {...rest}
                  items={GENDERS}
                  labelProp="shortTitle"
                  data-test="gender-select"
                  error={errors.gender?.message}
                  label="Gender"
                />
              )}
              rules={required()}
              name="gender"
              defaultValue=""
              control={control}
            />

            <Controller
              render={(controllerProps) => (
                <DatePicker
                  {...controllerProps}
                  fullWidth
                  variant="date"
                  error={errors.programEnrollmentDay?.message}
                  data-test="target-date-field"
                  label="Target enrollment date"
                  disablePast
                />
              )}
              rules={required()}
              name="programEnrollmentDay"
              defaultValue=""
              control={control}
            />

            <Controller
              render={(rest) => (
                <Select
                  {...rest}
                  items={ServiceInquryPreferedEnrollmentSchedule}
                  labelProp="name"
                  error={errors.preferedEnrollmentSchedule?.message}
                  label="Preferred enrollment schedule"
                />
              )}
              name="preferedEnrollmentSchedule"
              defaultValue=""
              control={control}
            />

            <Button
              variant="contained"
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              onClick={submit}
              fullWidth
              data-test="submit-button"
              css={css.sendButton}
              loading={formState.isSubmitting || formState.isSubmitSuccessful}
            >
              Send
            </Button>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default TourInquiryPage;
